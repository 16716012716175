import { template as template_d396251c6da14f1f83b3057abf4040dc } from "@ember/template-compiler";
const FKControlMenuContainer = template_d396251c6da14f1f83b3057abf4040dc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

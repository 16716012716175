import { template as template_da30406f5adb4103bc283c81c274b834 } from "@ember/template-compiler";
const SidebarSectionMessage = template_da30406f5adb4103bc283c81c274b834(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

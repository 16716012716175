import { template as template_fbe92876ec0742c0b992ea2383ee0529 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_fbe92876ec0742c0b992ea2383ee0529(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;

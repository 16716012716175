import { template as template_afd4de89e0d34f76993ee8c9ef92324f } from "@ember/template-compiler";
const FKText = template_afd4de89e0d34f76993ee8c9ef92324f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
